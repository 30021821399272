<template>
  <v-card v-if="accountData != null" flat class="pa-3 mt-2">
    <v-card-text class="d-flex">
      <!-- upload photo -->
      <div>
        <avatar-image-component
          :edit="adminProfileStore.id == accountData.id"
          :avatarUrl="accountData.avatar"
          :user="accountData"
        ></avatar-image-component>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        class="multi-col-validation mt-6"
        lazy-validation
      >
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="accountData.name"
              label="Name"
              dense
              outlined
              required
              :rules="nameRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="accountData.email"
              label="E-mail"
              :disabled="accountData.id != 1"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              disabled
              v-model="accountData.role_id"
              dense
              outlined
              label="Role"
              :items="roles"
              item-text="role"
              item-value="value"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="accountData.state"
              dense
              outlined
              label="State"
              :items="listState"
              item-text="state"
              item-value="value"
              @change="updateDistricts"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="accountData.district"
              dense
              outlined
              label="District"
              :items="filteredDistricts"
              item-text="district"
              item-value="value"
              :disabled="!accountData.state"
            ></v-select>
          </v-col>

          

          <v-col cols="12" md="6">
            <v-select
              v-model="accountData.status_id"
              :disabled="accountData.id == 1"
              dense
              outlined
              label="Status"
              :items="statuses"
              item-text="status"
              item-value="value"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="accountData.tel_number"
              outlined
              dense
              label="Phone"
              :rules="phoneRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" v-if="userType == 'student'">
            <v-text-field
              v-model="accountData.student_identification"
              outlined
              dense
              label="Student ID"
            ></v-text-field>
          </v-col>
          <v-col
            v-else-if="
              userType == 'school' ||
              (userType == 'guardian' && accountData.role_id == 4)
            "
          >
            <v-text-field
              v-model="accountData.balance"
              outlined
              dense
              label="Balance"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" v-if="userType == 'admin' || userType == 'student'">
            <v-select
              v-model="userRouteMorningID"
              dense
              outlined
              label="Route Morning"
              item-text="name"
              item-value="id"
              :items="userRouteMorning"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" v-if="userType == 'admin' || userType == 'student'">
            <v-select
             v-model="userRouteEverningID"
              dense
              outlined
              label="Route Everning"
              item-text="name"
              item-value="id"
              :items="userRouteEverning"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" class="me-3 mt-4" @click="saveUser">
              Save changes
            </v-btn>
            <v-btn color="secondary" outlined class="mt-4" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import AvatarImageComponent from "../../../components/AvatarImageComponent.vue";
import { mdiAlertOutline, mdiCloudUploadOutline } from "@mdi/js";
import { adminProfileStore } from "@/utils/helpers";

export default {
  components: {
    AvatarImageComponent,
  },
  setup() {
    return { adminProfileStore };
  },
  props: {
    accountData: {
      type: Object,
      default: () => {},
      state: "",
      district: "",
    },
    userType: {
      type: String,
      default: () => {},
    },
    userRouteMorning: {
      type: Array,
      default: () => [],
    },
    userRouteEverning: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: true,
      nameRules: [(v) => !!v || "Name is required"],
      // emailRules: [
      //   (v) => !!v || "E-mail is required",
      //   (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      // ],
      phoneRules: [(v) => /^(|\d)+$/.test(v) || "Phone must be valid"],
      statuses: [
        { status: "Active", value: 1 },
        //{ status: "Pending", value: 2 },
        { status: "Suspended", value: 3 },
      ],
      roles: [
        { role: "Admin", value: 1 },
        { role: "School", value: 2 },
        { role: "Driver", value: 3 },
        { role: "Parent", value: 4 },
        { role: "Guardian", value: 5 },
        { role: "Student", value: 6 },
      ],
      listState: [
        { state: "Perlis", value: "Perlis" },
        { state: "Kedah", value: "Kedah" },
        { state: "Pulau Pinang", value: "Pulau Pinang" },
        { state: "Johor", value: "Johor" },
        { state: "Terengganu", value: "Terengganu" },
        { state: "Pahang", value: "Pahang" },
        { state: "Kelantan", value: "Kelantan" },
        { state: "Kuala Lumpur", value: "Kuala Lumpur" },
        { state: "Selangor", value: "Selangor" },
        { state: "Melaka", value: "Melaka" },
        { state: "Perak", value: "Perak" },
        { state: "Sabah", value: "Sabah" },
        { state: "Sarawak", value: "Sarawak" },
      ],
      listDistricts: {
        Perlis: [
          { district: "Kangar", value: "Kangar" },
          { district: "Arau", value: "Arau" },
          { district: "Padang Besar", value: "Padang Besar" },
        ],
        Kedah: [
          { district: "Alor Setar", value: "Alor Setar" },
          { district: "Baling", value: "Baling" },
          { district: "Bandar Baharu", value: "Bandar Baharu" },
          { district: "Kota Setar", value: "Kota Setar" },
          { district: "Kuala Muda", value: "Kuala Muda" },
          { district: "Kubang Pasu", value: "Kubang Pasu" },
          { district: "Kulim", value: "Kulim" },
          { district: "Langkawi", value: "Langkawi" },
          { district: "Padang Terap", value: "Padang Terap" },
          { district: "Pendang", value: "Pendang" },
          { district: "Pokok Sena", value: "Pokok Sena" },
          { district: "Sik", value: "Sik" },
          { district: "Yan", value: "Yan" },
        ],
        "Pulau Pinang": [
          { district: "Barat Daya", value: "Barat Daya" },
          { district: "Timur Laut", value: "Timur Laut" },
          { district: "Seberang Perai Tengah", value: "Seberang Perai Tengah" },
          {
            district: "Seberang Perai Selatan",
            value: "Seberang Perai Selatan",
          },
          { district: "Seberang Perai Utara", value: "Seberang Perai Utara" },
        ],
        Johor: [
          { district: "Batu Pahat", value: "Batu Pahat" },
          { district: "Johor Bahru", value: "Johor Bahru" },
          { district: "Kluang", value: "Kluang" },
          { district: "Kota Tinggi", value: "Kota Tinggi" },
          { district: "Kulai", value: "Kulai" },
          { district: "Mersing", value: "Mersing" },
          { district: "Muar", value: "Muar" },
          { district: "Pontian", value: "Pontian" },
          { district: "Segamat", value: "Segamat" },
          { district: "Tangkak", value: "Tangkak" },
        ],
        Terengganu: [
          { district: "Besut", value: "Besut" },
          { district: "Dungun", value: "Dungun" },
          { district: "Hulu Terengganu", value: "Hulu Terengganu" },
          { district: "Kemaman", value: "Kemaman" },
          { district: "Kuala Terengganu", value: "Kuala Terengganu" },
          { district: "Marang", value: "Marang" },
          { district: "Setiu", value: "Setiu" },
        ],
        Pahang: [
          { district: "Bentong", value: "Bentong" },
          { district: "Bera", value: "Bera" },
          { district: "Cameron Highlands", value: "Cameron Highlands" },
          { district: "Jerantut", value: "Jerantut" },
          { district: "Kuantan", value: "Kuantan" },
          { district: "Lipis", value: "Lipis" },
          { district: "Maran", value: "Maran" },
          { district: "Pekan", value: "Pekan" },
          { district: "Raub", value: "Raub" },
          { district: "Rompin", value: "Rompin" },
          { district: "Temerloh", value: "Temerloh" },
        ],
        Kelantan: [
          { district: "Bachok", value: "Bachok" },
          { district: "Gua Musang", value: "Gua Musang" },
          { district: "Jeli", value: "Jeli" },
          { district: "Kota Bharu", value: "Kota Bharu" },
          { district: "Kuala Krai", value: "Kuala Krai" },
          { district: "Machang", value: "Machang" },
          { district: "Pasir Mas", value: "Pasir Mas" },
          { district: "Pasir Puteh", value: "Pasir Puteh" },
          { district: "Tanah Merah", value: "Tanah Merah" },
          { district: "Tumpat", value: "Tumpat" },
        ],
        "Kuala Lumpur": [
          { district: "Sentul", value: "Sentul" },
          { district: "Setapak", value: "Setapak" },
          { district: "Wangsa Maju", value: "Wangsa Maju" },
          { district: "Batu", value: "Batu" },
          { district: "Titiwangsa", value: "Titiwangsa" },
          { district: "Lembah Pantai", value: "Lembah Pantai" },
          { district: "Kepong", value: "Kepong" },
          { district: "Cheras", value: "Cheras" },
          { district: "Seputeh", value: "Seputeh" },
          { district: "Bandar Tun Razak", value: "Bandar Tun Razak" },
          { district: "Bukit Bintang", value: "Bukit Bintang" },
          { district: "Segambut", value: "Segambut" },
        ],
        Selangor: [
          { district: "Gombak", value: "Gombak" },
          { district: "Hulu Langat", value: "Hulu Langat" },
          { district: "Hulu Selangor", value: "Hulu Selangor" },
          { district: "Klang", value: "Klang" },
          { district: "Kuala Langat", value: "Kuala Langat" },
          { district: "Kuala Selangor", value: "Kuala Selangor" },
          { district: "Petaling", value: "Petaling" },
          { district: "Sabak Bernam", value: "Sabak Bernam" },
          { district: "Sepang", value: "Sepang" },
        ],
        Melaka: [
          { district: "Alor Gajah", value: "Alor Gajah" },
          { district: "Melaka Tengah", value: "Melaka Tengah" },
          { district: "Jasin", value: "Jasin" },
        ],
        Perak: [
          { district: "Batang Padang", value: "Batang Padang" },
          { district: "Hilir Perak", value: "Hilir Perak" },
          { district: "Hulu Perak", value: "Hulu Perak" },
          { district: "Kerian", value: "Kerian" },
          { district: "Kinta", value: "Kinta" },
          { district: "Kuala Kangsar", value: "Kuala Kangsar" },
          {
            district: "Larut, Matang, and Selama",
            value: "Larut, Matang, and Selama",
          },
          { district: "Manjung", value: "Manjung" },
          { district: "Perak Tengah", value: "Perak Tengah" },
          { district: "Muallim", value: "Muallim" },
          { district: "Kampar", value: "Kampar" },
        ],
        Sabah: [
          { district: "Beaufort", value: "Beaufort" },
          { district: "Beluran", value: "Beluran" },
          { district: "Kalabakan", value: "Kalabakan" },
          { district: "Keningau", value: "Keningau" },
          { district: "Kota Belud", value: "Kota Belud" },
          { district: "Kota Kinabalu", value: "Kota Kinabalu" },
          { district: "Kota Marudu", value: "Kota Marudu" },
          { district: "Kuala Penyu", value: "Kuala Penyu" },
          { district: "Kudat", value: "Kudat" },
          { district: "Kunak", value: "Kunak" },
          { district: "Lahad Datu", value: "Lahad Datu" },
          { district: "Nabawan", value: "Nabawan" },
          { district: "Papar", value: "Papar" },
          { district: "Penampang", value: "Penampang" },
          { district: "Pitas", value: "Pitas" },
          { district: "Putatan", value: "Putatan" },
          { district: "Ranau", value: "Ranau" },
          { district: "Sandakan", value: "Sandakan" },
          { district: "Semporna", value: "Semporna" },
          { district: "Sipitang", value: "Sipitang" },
          { district: "Tambunan", value: "Tambunan" },
          { district: "Tamparuli", value: "Tamparuli" },
          { district: "Tenom", value: "Tenom" },
          { district: "Tuaran", value: "Tuaran" },
          { district: "Telupid", value: "Telupid" },
          { district: "Tongod", value: "Tongod" },
          { district: "Keningau", value: "Keningau" },
        ],
        Sarawak: [
          { district: "Betong", value: "Betong" },
          { district: "Bintulu", value: "Bintulu" },
          { district: "Kapit", value: "Kapit" },
          { district: "Kuching", value: "Kuching" },
          { district: "Limbang", value: "Limbang" },
          { district: "Miri", value: "Miri" },
          { district: "Mukah", value: "Mukah" },
          { district: "Samarahan", value: "Samarahan" },
          { district: "Sarikei", value: "Sarikei" },
          { district: "Serian", value: "Serian" },
          { district: "Sibu", value: "Sibu" },
          { district: "Sri Aman", value: "Sri Aman" },
          { district: "Balingian", value: "Balingian" },
          { district: "Bau", value: "Bau" },
          { district: "Belaga", value: "Belaga" },
          { district: "Beluru", value: "Beluru" },
          { district: "Daro", value: "Daro" },
          { district: "Kanowit", value: "Kanowit" },
          { district: "Lundu", value: "Lundu" },
          { district: "Lubok Antu", value: "Lubok Antu" },
          { district: "Marudi", value: "Marudi" },
          { district: "Matu", value: "Matu" },
          { district: "Pakan", value: "Pakan" },
          { district: "Pusa", value: "Pusa" },
          { district: "Sebauh", value: "Sebauh" },
          { district: "Selangau", value: "Selangau" },
          { district: "Song", value: "Song" },
          { district: "Subis", value: "Subis" },
          { district: "Tatau", value: "Tatau" },
          { district: "Tebedu", value: "Tebedu" },
        ],
      },
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      userRouteMorningID : null,
      userRouteEverningID : null
    };
  },
  computed: {
    filteredDistricts() {
      if(this.userType === "admin" || this.userType === "student"){
      this.userRouteMorningID = this.accountData.student_route != null ? this.accountData.student_route[0] : null;
      this.userRouteEverningID = this.accountData.student_route != null ? this.accountData.student_route[1] : null;
      }
      return this.listDistricts[this.accountData.state] || [];
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    saveUser() {
      if (!this.validate()) return;
      if(this.userType === "admin" || this.userType === "student"){
      this.accountData.student_route = [this.userRouteMorningID,this.userRouteEverningID];
      }
      //this.$emit("save-user-info");
      this.$emit("save-user-info", this.accountData);
    },
    cancel() {
      this.$router.go(-1);
    },
    updateDistricts() {
      this.accountData.district = ""; // Reset district when state changes
    },
  },
};
</script>
